import React, { ReactElement, useEffect, useState } from "react";

import { BasketBodyCell, BasketBodyMobileContainer, BasketBodySpan, BasketButton, BasketContainer, BasketHeaderCell, BasketHelp, BasketHelpContainer, BasketInput, BasketOrderContainer, BasketStyledWrapper, BasketTableBodyContainer, BasketTableBodyMobileContainer, BasketTableContainer, BasketTableHeaderContainer, BasketTableHeaderMobileContainer, BasketText, CloseButton, DetailedText, ImageContainerModal, ImageWrapper, ItemImage, OrderButton, OrderInfo, QuantityChangeBlock, TitleBasket } from "./Bascet.styled";
import { observer } from "mobx-react-lite";
import { useBascetStore } from "../../hooks/use-bascet-store-hook/useBacetStore";

import { IoTrashOutline } from "react-icons/io5";
import { FiPlus } from "react-icons/fi";
import { FiMinus } from "react-icons/fi";

import { BascetInterface } from "../../types/interfaces/Bascet.interface";
import { useUserStore } from "../../hooks/use-user-store-hook/useUserStore";
import { CustomCheckBox } from "../../components/shared-components/CustomCheckBox/CustomCheckBox";
import Modal from "react-modal"
import { RxCross2 } from "react-icons/rx";
import { FaMagnifyingGlass } from "react-icons/fa6";
import { orderApi } from "../../api/requests/order.api";
import { gardenItemApi } from "../../api/requests/garden-item.api";
import { helpRequestApi } from "../../api/requests/help-request.api";
import { useNavigate } from "react-router-dom";

interface BasketPageProps { }

const tableHeaderItems = [
  {
    id: 1,
    name: "",
  },
  {
    id: 2,
    name: "Product name",
  },
  {
    id: 3,
    name: "Quantity",
  },
  {
    id: 4,
    name: "Pots",
  },
  {
    id: 5,
    name: "Weight",
  },
  {
    id: 6,
    name: "Price",
  },
  {
    id: 7,
    name: "Total",
  }

];

const tableHeaderMobileItems = [
  {
    id: 1,
    name: "",
  },
  {
    id: 3,
    name: "Pots",
  },
  {
    id: 4,
    name: "Weight",
  },
  {
    id: 5,
    name: "Price",
  },
  {
    id: 6,
    name: "Total",
  },
  {
    id: 7,
    name: "Quantity",
  }

];

interface UpdatedItem {
  id: string;
  data: {
    [key: string]: string | number | boolean | Date | any[] | number[] | object | undefined;

  };
  pots: {
    _id: string;
    weight: number;
    price: number;
    volume: number;
    quantity: number;
    isAvailable: boolean;
  }[];
  image: string;
  garden: string;
}

export const BasketPage = observer((props: BasketPageProps): ReactElement => {
  const { bascet, summaryWeight, clearBasket, deleteItemFromBascet, updateItemFromBascet } = useBascetStore();
  const { user } = useUserStore();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalImageSrc, setModalImageSrc] = useState("");
  const [orderSuccessModalIsOpen, setOrderSuccessModalIsOpen] = useState(false);
  const [minWeightMessage, setMinWeightMessage] = useState<string | null>(null);
  const [orderNumber, setOrderNumber] = useState<string | null>(null);
  const navigate = useNavigate();

  React.useEffect(() => {
    resetClickCountAtMidnight();
  }, []);

  
  useEffect(() => {
    document.title = `Basket : A&O Plants`;
  }, []);


  const resetClickCountAtMidnight = () => {
    const now: any = new Date();
    const midnight: any = new Date();
    midnight.setHours(24, 0, 0, 0);
    const timeToMidnight = midnight - now;
    setTimeout(() => {
      localStorage.removeItem('clickCount');
      resetClickCountAtMidnight();
    }, timeToMidnight);
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value;

    const digitsOnly = value.replace(/\D/g, '');

    let formattedValue = '';

    if (digitsOnly.length > 0) {
      if (digitsOnly.startsWith('44')) {
        formattedValue = '+44 ';
        formattedValue += digitsOnly.substring(2, 6);
        if (digitsOnly.length > 6) {
          formattedValue += ' ' + digitsOnly.substring(6, 11);
        }
        if (digitsOnly.length > 11) { // Добавляем проверку на дополнительную цифру
          formattedValue += digitsOnly.substring(11);
        }
      } else {
        formattedValue = '+44 ';
        formattedValue += digitsOnly.substring(0, 4);
        if (digitsOnly.length > 4) {
          formattedValue += ' ' + digitsOnly.substring(4, 9);
        }
        if (digitsOnly.length > 9) {
          formattedValue += ' ' + digitsOnly.substring(9);
        }
      }
    }

    setPhoneNumber(formattedValue);
  };

  const handleButtonClick = async () => {
    const stringWithoutSpaces = phoneNumber.replace(/\s+/g, '');
    if (stringWithoutSpaces.length !== 13) {
      setErrorMessage("Please enter your mobile number.");
      setSuccessMessage("");
      return;
    } else {
      setErrorMessage("");
    }
    const lastClickTime: any = localStorage.getItem('lastClickTime');
    const clickCount: any = parseInt(localStorage.getItem('clickCount') ?? '0', 10);
    const now: any = new Date().getTime();

    if (lastClickTime && now - lastClickTime < 60000) {
      const remainingTime = Math.ceil((60000 - (now - lastClickTime)) / 1000);
      setErrorMessage(`Please wait for ${remainingTime} seconds before clicking again.`);
      setSuccessMessage("");
      return;
    }

    if (clickCount >= 2) {
      setErrorMessage("You can only click this button twice per day.");
      setSuccessMessage("");
      return;
    }

    localStorage.setItem('lastClickTime', now);
    localStorage.setItem('clickCount', clickCount + 1);

    const order = await helpRequestApi.createHelpRequest({
      mobileNumber: stringWithoutSpaces
    });
    setSuccessMessage("Wait for a call from our manager");
    setTimeout(() => {
      setSuccessMessage("");
    }, 3000);
  };

  const handleButtonDeleteClick = (id: any) => {
    deleteItemFromBascet(id);
  };

  const handleIncreaseItem = (basketItem: BascetInterface) => {
    const maxQuantity = basketItem.item.pots[basketItem.selectedPot].quantity;
    const nextQuantity = basketItem.quantity + 5;
    basketItem.quantity = Math.min(nextQuantity, maxQuantity);
    updateItemFromBascet(basketItem);
  };

  const handleDecreaseItem = (basketItem: BascetInterface) => {
    const nextQuantity = Math.floor((basketItem.quantity - 1) / 5) * 5;
    basketItem.quantity = Math.max(nextQuantity, 5);
    updateItemFromBascet(basketItem);
  };

  const handlePlaceOrder = async () => {
    if (user?.id) {
      const palletWeight = 400;
      const margin = 10;
      const remainder = (summaryWeight % palletWeight);
      const palletsCount = Math.floor(summaryWeight / palletWeight);

      if (summaryWeight < 400) {
        setMinWeightMessage('Minimum order weight 400 kg');
        return;
      }
      if (summaryWeight > 420 && summaryWeight <= 790) {
        setMinWeightMessage(`At the moment, you have ${summaryWeight} kg in your basket, which constitutes ${palletsCount} pallet${palletsCount > 1 ? 's' : ''} and ${remainder.toFixed(1)} kg. To place an order, you need to either remove ${remainder.toFixed(1)} kg or add to your order to reach ${(summaryWeight + (palletWeight - remainder)).toFixed(1)} kg (+-${margin} kg).`);
        return;
      }
      setMinWeightMessage("")
      const order = await orderApi.createOrder(bascet, user, isChecked);
      const updatedItems: UpdatedItem[] = bascet.reduce((acc: UpdatedItem[], singleBasket: BascetInterface) => {
        const existingItem = acc.find(item => item.id === singleBasket.item.id);

        if (existingItem) {
          existingItem.pots = existingItem.pots.map((pot, index) => {
            if (index === singleBasket.selectedPot) {
              return {
                ...pot,
                quantity: pot.quantity - singleBasket.quantity
              };
            }
            return pot;
          });
        } else {
          const updatedItem = { ...singleBasket.item };
          const updatedPots = updatedItem.pots.map((pot, index) => {
            if (index === singleBasket.selectedPot) {
              return {
                ...pot,
                quantity: pot.quantity - singleBasket.quantity
              };
            }
            return pot;
          });
          updatedItem.pots = updatedPots;
          acc.push({
            data: updatedItem.data,
            pots: updatedItem.pots,
            image: updatedItem.image.id,
            garden: updatedItem.garden.id,
            id: updatedItem.id
          });
        }

        return acc;
      }, []);

      const updatePromises = updatedItems.map(async (item, index) => {
        try {
          const updatedProduct = await gardenItemApi.updateGardenItem(item.id, item);
          return updatedProduct;
        } catch (error) {
          return null;
        }
      });

      await Promise.all(updatePromises);

      setOrderNumber(order.orderNumber)
      clearBasket();
      setOrderSuccessModalIsOpen(true);
    } else {
      navigate("/signup");
    }

  };

  function palletDiscount(palletNumbers: number): number {
    if (palletNumbers < 3) {
      return palletNumbers;
    } else if (palletNumbers === 3) {
      return 4;
    } else return 6;
  }

  function totalyAmount(): number {
    const sumDiscount = palletDiscount(Math.floor(summaryWeight / 400)) +
      (user?.personalDiscount ? parseInt(user.personalDiscount) : 0) +
      (isChecked ? 2 : 0);
    const sumPrice = bascet.reduce((total, plant) => {
      return total + (plant.item.pots[plant.selectedPot].price * plant.quantity);
    }, 0);
    const discountAmount = sumPrice * (sumDiscount / 100);
    const priceWithDiscount = sumPrice - discountAmount;
    return priceWithDiscount;
  }

  const openModal = (src: string) => {
    setModalImageSrc(src);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const getBasketStatus = (summaryWeight: number): any => {
    const palletWeight = 400;
    const margin = 10;
    const remainder = (summaryWeight % palletWeight);
    const palletsCount = Math.floor(summaryWeight / palletWeight);

    if (summaryWeight < palletWeight) {
      return `At the moment, you have ${summaryWeight} kg in your basket, which is not sufficient for the minimum order. The minimum order is ${palletWeight.toFixed(1)} kg.`;
    } else if (remainder > palletWeight - margin) {
      return `At the moment, you have ${summaryWeight} kg in your basket, which constitutes ${palletsCount} pallet${palletsCount > 1 ? 's' : ''} and ${remainder.toFixed(1)} kg. To place an order, you need to either remove ${remainder.toFixed(1)} kg or add to your order to reach ${(summaryWeight + (palletWeight - remainder)).toFixed(1)} kg (+-${margin} kg).`

    } else if (remainder <= margin || remainder === 0) {
      return `At the moment, you have ${summaryWeight} kg in your basket, which constitutes ${palletsCount} pallet${palletsCount > 1 ? 's' : ''}.`;

    } else {
      return `At the moment, you have ${summaryWeight} kg in your basket, which constitutes ${palletsCount} pallet${palletsCount > 1 ? 's' : ''} and ${remainder.toFixed(1)} kg.`

    }
  };

  return (
    <BasketStyledWrapper>
      <BasketContainer>
        <BasketHelpContainer>
          <TitleBasket>
            Basket
          </TitleBasket>
          <BasketText>
            {getBasketStatus(summaryWeight)}
            {/* At the moment, you have {summaryWeight.toFixed(1)} kg in your basket, which constitutes {Math.floor(summaryWeight / 400)} pallet */}
          </BasketText>
          <BasketHelp>
            <BasketInput type="text" placeholder="+44 xxxx xxxxxx"
              value={phoneNumber} onChange={handleInputChange} maxLength={15} />
            <BasketButton onClick={handleButtonClick}>Need help</BasketButton>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              {errorMessage && <div style={{ color: 'white', backgroundColor: "#FF5F5F", borderRadius: "4px", padding: "9px" }}>{errorMessage}</div>}
              {successMessage && <div style={{ color: 'black', backgroundColor: "#CCF2CC", borderRadius: "4px", padding: "9px" }}>{successMessage}</div>}
            </div>
          </BasketHelp>

        </BasketHelpContainer>
        <BasketTableContainer>
          <BasketTableHeaderContainer>
            {tableHeaderItems.map(({ id, name }) => {
              return <BasketHeaderCell key={id}>{name}</BasketHeaderCell>
            })}
          </BasketTableHeaderContainer>
          <BasketTableHeaderMobileContainer>
            {tableHeaderMobileItems.map(({ id, name }) => {
              return <BasketHeaderCell key={id}>{name}</BasketHeaderCell>
            })}
          </BasketTableHeaderMobileContainer>
          <BasketTableBodyContainer>
            {bascet.length === 0 ? (
              <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', margin: "100px, 0" }}>
                Your basket is empty
              </div>
            ) : (
              null
            )}
            {bascet.map(({ id, item, selectedPot, quantity }) => {
              return <BasketBodyCell>
                <BasketBodySpan style={{ justifyContent: "space-around", padding: "0px 10px" }}>
                  <div>
                    <IoTrashOutline style={{ cursor: "pointer" }} size={22} onClick={() => handleButtonDeleteClick(id)} />
                  </div>
                  <ImageWrapper onClick={() => openModal(item.image.imageUrl)}>
                    <FaMagnifyingGlass />
                    <ItemImage src={item.image.imageUrl} alt="Example" />
                  </ImageWrapper>
                </BasketBodySpan>
                <BasketBodySpan>{item.data.name}</BasketBodySpan>
                <BasketBodySpan>
                  <QuantityChangeBlock onClick={() => handleIncreaseItem({ id, item, selectedPot, quantity })}><FiPlus /></QuantityChangeBlock>
                  {quantity}
                  <QuantityChangeBlock onClick={() => handleDecreaseItem({ id, item, selectedPot, quantity })}><FiMinus /></QuantityChangeBlock>
                </BasketBodySpan>
                <BasketBodySpan>{item.pots[selectedPot].volume}l</BasketBodySpan>
                <BasketBodySpan>{item.pots[selectedPot].weight}kg</BasketBodySpan>
                <BasketBodySpan>{item.pots[selectedPot].price}£</BasketBodySpan>
                <BasketBodySpan>{(item.pots[selectedPot].price * quantity)}£</BasketBodySpan>
              </BasketBodyCell>
            })}
          </BasketTableBodyContainer>
          <BasketTableBodyMobileContainer>
            {bascet.length === 0 ? (
              <div style={{ textAlign: 'center', fontSize: '20px', fontWeight: 'bold', margin: "40px, 0" }}>
                Your basket is empty
              </div>
            ) : (
              null
            )}
            {bascet.map(({ id, item, selectedPot, quantity }) => {
              return <BasketBodyMobileContainer>
                <div style={{ textAlign: "left", fontSize: "15px", margin: "4px 0 0 15px", fontWeight: "bold" }}>
                  {item.data.name}
                </div>
                <BasketBodyCell>
                  <BasketBodySpan style={{ justifyContent: "space-around", padding: "0 0 0 10px", width: "26%" }}>
                    <div>
                      <IoTrashOutline style={{ cursor: "pointer" }} size={22} onClick={() => handleButtonDeleteClick(id)} />
                    </div>
                    <ImageWrapper onClick={() => openModal(item.image.imageUrl)}>
                      <FaMagnifyingGlass />
                      <ItemImage src={item.image.imageUrl} alt="Example" />
                    </ImageWrapper>
                  </BasketBodySpan>

                  <BasketBodySpan>{item.pots[selectedPot].volume}l</BasketBodySpan>
                  <BasketBodySpan>{item.pots[selectedPot].weight}kg</BasketBodySpan>
                  <BasketBodySpan>{item.pots[selectedPot].price}£</BasketBodySpan>
                  <BasketBodySpan>{(item.pots[selectedPot].price * quantity)}£</BasketBodySpan>
                  <BasketBodySpan style={{ width: "18%", justifyContent: "space-between" }}>
                    <QuantityChangeBlock onClick={() => handleIncreaseItem({ id, item, selectedPot, quantity })}><FiPlus /></QuantityChangeBlock>
                    {quantity}
                    <QuantityChangeBlock onClick={() => handleDecreaseItem({ id, item, selectedPot, quantity })}><FiMinus /></QuantityChangeBlock>
                  </BasketBodySpan>
                </BasketBodyCell>
              </BasketBodyMobileContainer>
            })}
          </BasketTableBodyMobileContainer>
        </BasketTableContainer>
        <BasketOrderContainer>
          <OrderInfo>
            <div>Quantity pallets</div>
            <DetailedText>{Math.floor(summaryWeight / 400)}</DetailedText>
          </OrderInfo>
          <OrderInfo>
            <div>Pallet quantity discount</div>
            <DetailedText>{palletDiscount(Math.floor(summaryWeight / 400))}%</DetailedText>
          </OrderInfo>
          <OrderInfo>
            <div style={{ textAlign: "right" }}>Individual discount for a loyal customer</div>
            <DetailedText>{user?.personalDiscount ? `${user.personalDiscount}%` : '0%'}</DetailedText>
          </OrderInfo>
          <OrderInfo>
            <div style={{ textAlign: "right" }}>I would like to receive a 2% discount and pay within 7 days.</div>
            <DetailedText>
              <CustomCheckBox checked={isChecked} onCheckChange={setIsChecked} />
            </DetailedText>
          </OrderInfo>
          <OrderInfo>
            <div>Total weight</div>
            <DetailedText>{summaryWeight.toFixed(1)}kg</DetailedText>
          </OrderInfo>
          <OrderInfo>
            <div>Total amount due</div>
            <DetailedText>{totalyAmount().toFixed(2)}£</DetailedText>
          </OrderInfo>
          <OrderInfo>
            <OrderButton onClick={handlePlaceOrder}>Place an order</OrderButton>
          </OrderInfo>
          <div style={{ display: "flex", justifyContent: "right", alignItems: "center" }}>
            {minWeightMessage && (
              <div style={{ color: 'white', backgroundColor: "#FF5F5F", borderRadius: "4px", padding: "9px", textAlign: 'right', marginTop: '10px', marginRight: '40px', width: "auto" }}>
                {minWeightMessage}
              </div>
            )}
          </div>
        </BasketOrderContainer>
      </BasketContainer>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Modal"
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            maxWidth: "90%",
            maxHeight: "80%",
            transform: 'translate(-50%, -50%)',
          },
        }}
      >
        <div>
          <CloseButton onClick={closeModal}><RxCross2 size={30} /></CloseButton>
          <ImageContainerModal >
            <img src={modalImageSrc} alt="Enlarged Example" style={{ width: "100%", height: "100%" }} />
          </ImageContainerModal>
        </div>
      </Modal>
      <Modal
        isOpen={orderSuccessModalIsOpen}
        onRequestClose={() => setOrderSuccessModalIsOpen(false)}
        contentLabel="Order Success Modal"
        ariaHideApp={false}
        style={{
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            padding: '20px',
            textAlign: 'center',
            border: 'none',
            borderRadius: '8px',
            backgroundColor: '#fff',
            boxShadow: '0 0 20px rgba(0, 0, 0, 0.2)',
          },
        }}
      >
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignContent: "center", alignItems: "center" }}>
          <h2>Order placed, expect a call from a manager.</h2>
          <h2 style={{ marginTop: "10px" }}>Your order number № {orderNumber}</h2>
          <OrderButton style={{ marginTop: "10px" }} onClick={() => setOrderSuccessModalIsOpen(false)}>Ok</OrderButton>
        </div>
      </Modal>
    </BasketStyledWrapper>
  );
});
