import React, { ReactElement } from "react";

import { PaginationButton, PaginationContainer, PaginationEllipsis, PaginationStyledWrapper } from "./Pagination.styled";
import { useLocation, useNavigate } from "react-router-dom";

interface PaginationProps {
  totalPages: number;
  currentPage: any;
  selectedValue: string;
  step?: number;
}
export const Pagination = ({
  totalPages,
  currentPage,
  selectedValue,
  step = 4,
}: PaginationProps): ReactElement => {
  const navigate = useNavigate();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const handlePageChange = (pageNumber: number) => {
    navigate(`?filter=${selectedValue}&page=${pageNumber}`);
    scrollToTop();
  };

  const addPage = (pageNumber: number, pages: JSX.Element[]) => {
    pages.push(
      <PaginationButton
        key={pageNumber}
        onClick={() => handlePageChange(pageNumber)}
        disabled={pageNumber === currentPage}
      >
        {pageNumber}
      </PaginationButton>
    );
  };

  const renderPagination = () => {
    const pages: JSX.Element[] = [];

    if (currentPage > 1) {
      pages.push(
        <PaginationButton key="prev" onClick={() => handlePageChange(currentPage - 1)}>
          {"<"}
        </PaginationButton>
      );
    }

    addPage(1, pages);

    if (totalPages <= 1) {
      return pages;
    }

    const halfStep = Math.floor(step / 2);
    let startPage = currentPage - halfStep;
    let endPage = currentPage + halfStep;

    if (startPage < 2) {
      startPage = 2;
      endPage = Math.min(totalPages - 1, startPage + step - 1);
    }
    if (endPage > totalPages - 1) {
      endPage = totalPages - 1;
      startPage = Math.max(2, endPage - step + 1);
    }

    if (startPage > 2) {
      pages.push(
        <PaginationEllipsis key="startEllipsis">...</PaginationEllipsis>
      );
    }

    for (let i = startPage; i <= endPage; i++) {
      addPage(i, pages);
    }

    if (endPage < totalPages - 1) {
      pages.push(
        <PaginationEllipsis key="endEllipsis">...</PaginationEllipsis>
      );
    }

    addPage(totalPages, pages);

    if (currentPage < totalPages) {
      pages.push(
        <PaginationButton key="next" onClick={() => handlePageChange(currentPage + 1)}>
          {">"}
        </PaginationButton>
      );
    }

    return pages;
  };

  return <PaginationContainer>{renderPagination()}</PaginationContainer>;
};