import React, { ReactElement, useEffect, useState } from "react";

import { AboutUsContainer, AboutUsImg, AboutUsInfo, AboutUsInfoContainer, AboutUsTitle, AccardioneContainer, ButtonDownloadFile, CatalogeFiles, DownloadTitle, HomeContainer, HomeStyledWrapper, HomeTitle, HowOrderContainer, OrderInfo, OrderTitle, PlantButton, PlantButtonContainer, PlantItem, PlantTitle, PlantsContainer, SearchMobileContainer, TitleText } from "./Home.styled";
import { Acardione } from "../../components/shared-components/Accardione/Accardione";
import { Search } from "../../components/shared-components/Search/Search"
import { gardenApi, GardenApi } from "../../api/requests/garden.api";
import { gardenItemApi } from "../../api/requests/garden-item.api";
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';

import { GardenItemInterface } from "../../types/interfaces/GardenItem.interface";
import { Pot } from "../../types/interfaces/Pot.interface";
import { Loader } from "../../components/shared-components/Loader/Loader";

const gardenImage = [
  "garden-image-mock/deciduous-shrubs-image.png",
  "garden-image-mock/perennials-image.png",
  "garden-image-mock/grass-decorations-image.png",
  "garden-image-mock/climber-image.png",
  "garden-image-mock/coniferous-shrubs-image.png"
]

interface HomePageProps { }

const questItems = [
  {
    title: "REGISTRATION GUIDE",
    content: (<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <ol style={{ marginLeft: "15px", display: "flex", flexDirection: "column", gap: "5px" }}>
        <li><strong>Locate "Sign Up":</strong> Find the "Sign Up" button in the upper right corner of the website and click it.</li>
        <li><strong>Access the Registration Menu:</strong> The "Registration" form will open automatically after clicking "Sign Up."</li>
        <li><strong>Fill Out Required Fields:</strong> Enter your name and contact phone number in the format +440000000000. This number will receive an SMS with a verification code. Ensure your postal code is correctly formatted with a space in the middle (e.g., AA77 7BB or AA77 77BB). For a smoother experience, consider also providing your email address, company address, and VAT number.</li>
        <li><strong>Submit Your Information:</strong> Once all required fields are completed, click the green "Register" button.</li>
        <li><strong>Check Your Messages:</strong> A message will appear instructing you to check your phone for an SMS sent to +440000000000. This message will contain a 4-digit verification code.</li>
        <li><strong>Enter the Verification Code:</strong> Input the received code into the empty cells provided on the page.</li>
        <li><strong>Confirm and Finish:</strong> Click "Confirm," and you’ll be redirected to the main page. Your name will now appear in the upper right corner, indicating your successful registration.</li>
      </ol>
      <p>You’re all set! You can now begin placing orders.</p>
    </div>)
  },
  {
    title: "ORDER INSTRUCTIONS",
    content: (<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <p><strong>IMPORTANT:</strong> Your order is complete only when the total weight of the items in your basket is a multiple of 400-420 kg, ensuring you've gathered a full pallet by weight. You will only be able to order products that are currently in stock.</p>
      <p>To place an order, follow these steps:</p>
      <ol style={{ marginLeft: "15px", display: "flex", flexDirection: "column", gap: "5px" }}>
        <li><strong>Navigate to "Plants":</strong> Start by clicking on the "Plants" section of the website.</li>
        <li><strong>Choose Your Section:</strong> Select the category where you can place an order. <strong>IMPORTANT:</strong> For the fall season of 2024-2025, only the "Deciduous shrubs" category is available.</li>
        <li><strong>Explore the Selection:</strong>
          <ul style={{ marginLeft: "15px", display: "flex", flexDirection: "column", gap: "5px" }}>
            <li >
              At the top of the menu, you’ll find active sections like "Sorting," "Pots," and "Quantity," as well as passive sections such as "Height," "Color," "Tree diameter," "Weight," and "Price." Passive sections will update based on changes in the "Pots" section, reflecting details per unit of production.
            </li>
            <li>Below, you’ll see a list of products in stock, starting with the smallest pot capacity available. To view other available sizes, click on the arrow in the "Pots" dropdown menu.</li>
            <li>For each product, the "Price" and "Weight" at the end of the row will adjust based on your selections in the "Quantity" section. These adjustments reflect the weight and cost of the selected quantity.</li>
          </ul>
        </li>
        <li><strong>Select Pot Capacity:</strong> In the "Sorting" menu at the upper left, choose the desired pot capacity. If you need to change the pot size, simply move the cursor to the desired volume in "Sorting" or use the arrow in the "Pots" dropdown menu.</li>
        <li><strong>Select Your Product:</strong> Browse the product list or use the search function near the logo at the top of the page to find the item you need (if it’s available in stock).</li>
        <li><strong>Choose Quantity:</strong> Use the arrows in the "Quantity" submenu to select the amount you need, then click the green "Add" button.
        </li>
        <li><strong>Complete Your Order:</strong> Your selected items are now in your basket. Remember, your order is only complete when the total weight is a multiple of 400-420 kg, indicating a full pallet by weight. If you haven't reached the required weight, repeat steps 4-6. If you’ve exceeded the needed weight, adjust your items in the "Basket."</li>
      </ol>
      <p><strong>Note:</strong> If a product is out of stock, you will receive a notification labeled "Item information update" when attempting to add it to your basket.</p>
    </div>),
  },
  {
    title: "BASKET INSTRUCTIONS",
    content: (<div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <p>Once you've added the desired products to your cart, you can review them by clicking on the cart icon located in the upper right corner of the screen. Here, you can adjust the quantities of your items or remove any products added by mistake.</p>
      <p>At the top of the basket, you'll find the following details:</p>
      <ul style={{ marginLeft: "15px" }}>
        <li><strong>Current Weight and Pallets:</strong> "At the moment, you have xxxx.x kg in your basket, which constitutes x pallets and xx.x kg."</li>
        <li><strong>Contact Information:</strong> Below this, you’ll see "+44 xxxx xxxxxx" and a "Need help" button. If you need assistance, click "Need help," and a prompt will appear asking you to "Please enter your mobile number." The "+44 xxxx xxxxxx" field will become active; simply enter your phone number, excluding the "+44" prefix, and one of our representatives will reach out to you.
        </li>
      </ul>
      <p>Next, you’ll see detailed information about the products in your basket, including:</p>
      <ul style={{ marginLeft: "15px" }}>
        <li><strong>Product name</strong></li>
        <li><strong>Quantity</strong></li>
        <li><strong>Pots</strong></li>
        <li><strong>Weight</strong></li>
        <li><strong>Price</strong></li>
        <li><strong>Total </strong>(which is the total price for each product based on the selected quantity)</li>
      </ul>
      <p>As you scroll to the bottom of the basket, you’ll find:</p>
      <ul style={{ marginLeft: "15px" }}>
        <li><strong>Quantity Pallets:</strong> The total number of pallets.</li>
        <li><strong>Pallet Quantity Discount:</strong> The discount based on the number of pallets purchased (e.g., a 2% discount for 2 pallets, 4% for 3 pallets, and 6% for 4 or more pallets).</li>
        <li><strong>Loyalty Discount:</strong> An individual discount for loyal customers, ranging from 1% to 5% based on your purchase history.</li>
        <li><strong>Additional Discount Option:</strong> A checkbox to receive an extra 2% discount if you're ready to pay for the shipped goods within 7 working days from the date of shipment.</li>
      </ul>
      <p>Finally, you’ll see the <strong>Total Weight</strong> and <strong>Total Amount Due</strong> displayed at the bottom of the basket, along with the <strong>"Place an order"</strong> button.</p>
      <p>Once you're satisfied with your selections, click the "Place an order" button. After doing so, you'll receive a confirmation message:</p>
      <p>"Order placed, expect a call from a manager. Your order number is № xx." Click "OK" to finalize, and our manager will contact you shortly to discuss the details of your order.</p>
    </div>)
  },
];

function formatString(input: any) {
  if (input === "volume") {
    input = "pots"
  }
  const words = input.split(/(?=[A-Z])/);

  const formattedWords = words.map((word: any, index: any) => {
    if (index === 0) {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    return word.toLowerCase();
  });

  return formattedWords.join(" ");
}

const exportToPDF = (products: GardenItemInterface[]) => {
  const doc = new jsPDF();

  // Добавляем шапку
  doc.setFontSize(12);
  doc.text('A&O PLANTS LIMITED', 16, 15);
  doc.setFontSize(10);
  doc.text('officeaiop@gmail.com', 16, 22);
  doc.text('aoplants.com', 16, 27);
  doc.text('+44 7832 695 407', 16, 32);

  let startY = 40; // Отступ после шапки

  products.forEach((product, index) => {
    const dataKeys = Object.keys(product.data);
    const fixedDateKeys = dataKeys.map(item => 
      item === "height" || item === "treeDiameter" ? formatString(item) + " (m)" : formatString(item)
    );

    const tableHead = [
      ...fixedDateKeys,
      'Volume (l)', 'Quantity', 'Price (£)', 'Weight (kg)'
    ];

    const tableBody = product.pots.map((pot: Pot) => [
      ...dataKeys.map(key => product.data[key] || '-'), // Теперь дублируем данные в каждой строке
      pot.volume || '-',
      pot.isAvailable ? pot.quantity || 0 : 0,
      pot.price || '-',
      pot.weight || '-',
    ]);

    (doc as any).autoTable({
      head: [tableHead],
      body: tableBody,
      startY: index === 0 ? startY : (doc as any).autoTable.previous.finalY + 10,
      styles: { cellPadding: 2, fontSize: 9, overflow: 'linebreak', halign: 'left', valign: 'middle' },
      margin: { top: 4 }
    });
  });

  doc.save('catalogue_all_products.pdf');
};

const exportToExcel = (products: GardenItemInterface[]) => {
  const worksheetData: any[] = [];

  // Добавляем шапку в Excel
  worksheetData.push(["A&O PLANTS LIMITED"]);
  worksheetData.push(["officeaiop@gmail.com"]);
  worksheetData.push(["aoplants.com"]);
  worksheetData.push(["+44 7832 695 407"]);
  worksheetData.push([]); // Пустая строка для отступа

  const headerMap: { [key: string]: string[] } = {};
  const rowDataMap: { [key: string]: any[] } = {};

  products.forEach((product) => {
    const dataKeys = Object.keys(product.data);
    const fixedDateKeys = dataKeys.map(item => 
      item === "height" || item === "treeDiameter" ? formatString(item) + " (m)" : formatString(item)
    );

    const headerKey = JSON.stringify(dataKeys);

    if (!headerMap[headerKey]) {
      headerMap[headerKey] = [
        ...fixedDateKeys,
        'Volume (l)', 'Quantity', 'Price (£)', 'Weight (kg)'
      ];
    }

    product.pots.forEach((pot: Pot) => {
      const row = [
        ...dataKeys.map(key => product.data[key] || '-'), // Теперь дублируем данные в каждой строке
        pot.volume || '-',
        pot.isAvailable ? pot.quantity || 0 : 0,
        pot.price || '-',
        pot.weight || '-',
      ];

      if (!rowDataMap[headerKey]) {
        rowDataMap[headerKey] = [];
      }
      rowDataMap[headerKey].push(row);
    });
  });

  Object.keys(headerMap).forEach(headerKey => {
    worksheetData.push(headerMap[headerKey]);

    if (rowDataMap[headerKey]) {
      worksheetData.push(...rowDataMap[headerKey]);
    }

    worksheetData.push([]); // Отступ между наборами данных
  });

  const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

  // Устанавливаем автоширину столбцов
  worksheet["!cols"] = worksheetData[0].map((col: any) => ({
    wch: col.length + 25 // Длина текста + немного отступа
  }));

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Products');

  XLSX.writeFile(workbook, 'catalogue_all_products.xlsx');
};


export const HomePage = (props: HomePageProps): ReactElement => {
  const [plants, setPlants] = useState<any[]>([]);
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    document.title = "Online Store A&O Plants";
    const fetchData = async () => {
      try {
        const dataGarden = await gardenApi.getGarden();
        setPlants(dataGarden);
        const dataGardenItem = await gardenItemApi.getGardenItem();
        setProducts(dataGardenItem)
      } catch (error) {
        console.error("Error fetching garden data:", error);
      }
    };
    fetchData();
    setLoading(false)
  }, []);

  return (
    <HomeStyledWrapper>
      <HomeContainer>
        <HomeTitle>
          <TitleText>A&O PLANTS is a leading wholesale</TitleText>
        </HomeTitle>

        <CatalogeFiles>
          <DownloadTitle><p>Download our full catalogue</p></DownloadTitle>
          <ButtonDownloadFile onClick={() => exportToPDF(products)}> PDF Format </ButtonDownloadFile>
          <ButtonDownloadFile onClick={() => exportToExcel(products)}>Excel format</ButtonDownloadFile>
        </CatalogeFiles>

        <SearchMobileContainer>
          <Search />
        </SearchMobileContainer>
        <PlantsContainer>
          {loading ? (
            <Loader style={{ width: "100%", height: '250px' }} />
          ) : (plants.map((plant: any, index: number) => {
            return (
              <PlantItem key={plant.id} imgurl={plant.image.imageUrl}>
                <PlantTitle >
                  {plant.name}
                </PlantTitle>
                <PlantButtonContainer >
                  <PlantButton >
                    <a style={{ width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }} href={`/gardens/${plant.id}`}>
                      View All
                    </a>
                  </PlantButton>
                </PlantButtonContainer>
              </PlantItem>

            )
          }))}
        </PlantsContainer>
        <AboutUsContainer>
          <AboutUsTitle>
            About us
          </AboutUsTitle>

          <AboutUsInfoContainer>
            <AboutUsInfo>
              A&O PLANTS is a wholesale supplier of top-quality plants, catering to businesses of all sizes. With a dedication to excellence, we provide a wide range of plants, meticulously cultivated to meet the highest standards. Our flexible approach to customer service ensures that we tailor our offerings to meet the unique needs of our clients. At A&O PLANTS, we prioritize quality, reliability, and customer satisfaction, making us the ideal partner for businesses seeking exceptional plants for their customers.              <br />
            </AboutUsInfo>
            <AboutUsInfo>
              <AboutUsImg src={"/about-us-image.jpg"} />
            </AboutUsInfo>
          </AboutUsInfoContainer>
        </AboutUsContainer>
        <HowOrderContainer>
          <OrderTitle>Wholesale Ordering Made Easy: Benefits and Discounts for Loyal Customers</OrderTitle>
          <OrderInfo>
            <p>
              Our online shop is tailored for wholesale buyers, with a focus on ensuring seamless and efficient transactions. To maintain this standard, our minimum order is set at 1 pallet, weighing between 400 to 420 kg. For larger orders, the weight should be in multiples of a pallet—for example, 2 pallets should weigh between 800 to 840 kg, and so on. If your order does not meet these requirements, we kindly ask that you adjust the quantities accordingly.
            </p>
            <p>
              All goods are delivered in pots, with the minimum order quantity determined by the pot size. This ensures that each order is optimized for both quality and convenience.
            </p>
            <p>
              We value long-term partnerships, which is why we've developed a loyalty program to reward our regular customers:
            </p>
            <ul>
              <li><strong>Pallet Quantity Discount:</strong> Enjoy savings when you purchase multiple pallets. A 2% discount is applied to orders of 2 pallets, a 4% discount for 3 pallets, and a 6% discount when you order 4 or more pallets.</li>
              <li><strong>Individual Loyalty Discount:</strong> This personalized discount ranges from 1% to 5%, based on your purchase frequency and volume.</li>
            </ul>
            <p>Additionally, you can instantly receive a 2% discount by selecting the option: "I would like to receive a 2% discount and pay within 7 days." This discount applies if you commit to paying for the goods within 7 working days from the date of shipment.</p>
            <p>We look forward to building a successful and lasting relationship with you.</p>
          </OrderInfo>
        </HowOrderContainer>
        <Acardione items={questItems} />
      </HomeContainer>
    </HomeStyledWrapper >
  );
};
