import styled from "styled-components";

export const AddItemButtonStyledWrapper = styled.div`
  width: 100%;
`;

export const AddItemButtonContainer = styled.div`

@media (max-width: 768px) {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
`;

export const InfoItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center; 
  user-select: none;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;


}
`;

export const ButtonContainer = styled.div`
  margin-top: 10px;
  user-select: none;

  @media (max-width: 768px) {
    height: 40px;
    width: 40px;
    margin-top: 0;
  }
`;



export const PriceInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: left;
    width: 100%;
    font-size:15px;

  }
`;

export const WeightInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    justify-content: left;
    width: 100%;
    font-size:15px;
    }

`;

export const VerticalLine = styled.div`
  display: block;

  width: 3px; 
  background-color: #00C000; 
  min-height: 26px; 
  margin: 0 3px;
  transition: background-color 0.3s ease;
  flex-shrink: 0;

  @media (max-width: 768px) {
  display: none;
}


  @supports selector(:has(*)) {
    ${InfoItemContainer}:has(${PriceInfoContainer} + ${WeightInfoContainer}) & {
      display: none;
    }
  }
`;
export const ButtonLink = styled.button<{ isDisabled: boolean }>`
  width: 100%;
  height: 100%;
  display: inline-block;
  padding: 8px 20px;
  font-size: 16px;
  color: white; 
  background-color: ${({ isDisabled }) => (isDisabled ? '#83CD83' : '#00C000')};
  border: 1px solid #ddd;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  cursor: ${({ isDisabled }) => (isDisabled ? 'not-allowed' : 'pointer')};
  transition: background-color 0.3s, color 0.3s;

  &:hover {
    background-color: ${({ isDisabled }) => (isDisabled ? '#83CD83' : '#009900')};
    color: white;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const PriceInfo = styled.a`
  margin-right: 6px;

`;

export const WeightInfo = styled.a`
  margin-right: 6px;

`;

export const TextLinkContainer = styled.div`
  height: 100%;
`;

export const Text = styled.div`
display: block;


@media (max-width: 768px) {
   display: none;
  }
`;

export const MobileText = styled.div`
display: none;
height: 100%;


@media (max-width: 768px) {
   display: flex;
   justify-content: center;
   align-items: center;
  }
`;


export const MobileDetails = styled.div`
display: none;
height: 100%;


@media (max-width: 768px) {
   display: flex;
   justify-content: center;
   align-items: center;
  }
`;

